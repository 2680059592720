<script setup>

</script>


<template>
  <div class="container-fluid mt-5">
    <h3 style="margin-bottom: 400px">Profile Page</h3>
  </div>
</template>

<style scoped>

</style>